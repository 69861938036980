import { off, ref, onValue, update, remove } from "firebase/database";
import { realtimeDatabase } from "../firebase";

export async function removeSession(hospitalId, deviceId) {
    const sessionsRef = ref(realtimeDatabase, `/${hospitalId}/sessions/${deviceId}`);
    return remove(sessionsRef);
}

export function updateRealTimeDbSession(hospitalId, deviceId, dataToUpdate) {
    const sessionsRef = ref(realtimeDatabase, `/${hospitalId}/sessions/${deviceId}`);
    return update(sessionsRef, dataToUpdate);
}

export function addSessionUpdateDbEvent(hospitalId, deviceId, callBack) {
    const sessionsRef = ref(realtimeDatabase, `/${hospitalId}/sessions/${deviceId}`);
    return onValue(sessionsRef, callBack, { onlyOnce: true });
}

export function addHospitalSessionsListener(hospitalId, callBack) {
    const sessionsRef = ref(realtimeDatabase, `/${hospitalId}/sessions`);
    return onValue(sessionsRef, callBack);
}

export function unsubscribeHospitalsListener(hospitalId) {
    const sessionsRef = ref(realtimeDatabase, `/${hospitalId}/sessions`);
    return off(sessionsRef, "value");
}

export function updateOnlineUsers(hospitalId, userId, data) {
    const onlineUsersRef = ref(realtimeDatabase, `/${hospitalId}/online_users/${userId}`);
    return update(onlineUsersRef, data);
}

export function removeOnlineUser(hospitalId, userId) {
    const onlineUserRef = ref(realtimeDatabase, `/${hospitalId}/online_users/${userId}`);
    return remove(onlineUserRef);
}