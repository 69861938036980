import {
    getMultiFactorResolver,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier,
    signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
} from "firebase/auth";
import { firebaseAuth } from "../firebase";

export function getAuthUser() {
    return firebaseAuth.currentUser;
}

export async function signInWithEmailAndPassword(username, password) {
    return firebaseSignInWithEmailAndPassword(firebaseAuth, username, password);
}

export async function resolveTwoFactorAuth(verificationId, otpCode, mfaResolver) {
    const credentials = PhoneAuthProvider.credential(verificationId, otpCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credentials);

    await mfaResolver.resolveSignIn(multiFactorAssertion);
}

export function setUpInvisibleRecaptcha(containerRecaptcha) {
    const recaptchaVerifier = new RecaptchaVerifier(
        containerRecaptcha,
        {
            size: "invisible",
        },
        firebaseAuth
    );

    return recaptchaVerifier;
}

export async function verifyPhoneNumber(phoneInfoOptions, appVerifierInstance) {
    const phoneAuthProvider = new PhoneAuthProvider(firebaseAuth);
    return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, appVerifierInstance);
}

export async function authenticationSignOut() {
    return firebaseAuth.signOut();
}

export function getMfaResolver(error) {
    return getMultiFactorResolver(firebaseAuth, error);
}
