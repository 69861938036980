import { addDoc, arrayUnion, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase";

export async function addBuddySessionsDecisions(sessionId, dataToUpdate) {
    const decisionData = {
        decisions: arrayUnion(dataToUpdate),
    };
    return updateBuddySessions(sessionId, decisionData);
}

export async function addBuddySessionActions(sessionId, dataToUpdate) {
    const actionData = {
        actions: arrayUnion(dataToUpdate),
    };

    return updateBuddySessions(sessionId, actionData);
}

export async function updateBuddySessions(sessionId, dataToUpdate) {
    const buddySessionsRef = doc(firestore, "buddy_sessions", sessionId);
    return updateDoc(buddySessionsRef, dataToUpdate);
}

export async function setBuddySession(sessionId, sessionData) {
    const buddySessionsRef = doc(firestore, "buddy_sessions", sessionId);
    return setDoc(buddySessionsRef, sessionData);
}

export async function addFailedLogins(dataToAdd) {
    const failedLoginsRef = collection(firestore, "failedLogins");
    return addDoc(failedLoginsRef, dataToAdd);
}
