import moment from "moment-timezone";

import { removeOnlineUser, updateOnlineUsers } from "./firebaseUtils/realtimeWrites";

const pingInterval = 30000;

const onlinePing = (hospitalId, userId, isBusy = false) => {
  return setInterval(changeBusyState, pingInterval, hospitalId, userId, isBusy);
};

const changeBusyState = (hospitalId, userId, isBusy) => {
  let pingTime = moment.utc().format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
  console.log(
    `Ping Time: ${pingTime} | Hospital: ${hospitalId} | User: ${userId} | IsBusy: ${isBusy}`
  );
  const updateData = {
    isBusy: isBusy,
    lastPing: pingTime,
  };

  updateOnlineUsers(hospitalId, userId, updateData);
};

const setOffline = (hospitalId, userId) => {
  removeOnlineUser(hospitalId, userId);
};

export { onlinePing, changeBusyState, setOffline };
