import React from "react";
import ReactDOM from "react-dom";
import rg4js from "raygun4js";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./utils/i18n";
import packageJson from '../package.json';
import LogRocket from 'logrocket';

if (process.env.NODE_ENV === 'production') {
  rg4js("enableCrashReporting", true);
  rg4js("apiKey", "Rmt7eL4gYIwzl7JID3OZQ");
  //rg4js('enablePulse', true);
  rg4js('setVersion', packageJson.version);
  //rg4js('saveIfOffline', true);

  LogRocket.init('1obpi7/buddy-hyp4s');
}

const Application = <React.StrictMode><App /></React.StrictMode>;

const domElement = document.getElementById("root")

ReactDOM.render(Application, domElement);

serviceWorker.register();
