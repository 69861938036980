import React from 'react';
import { currentEnviroment } from './firebase';

const ENV_COLOR = {
	'DEV': 'rgba(138, 29, 205, 0.5)',
	'UAT': 'rgba(205, 103, 29, 0.5)',
	'PROD': 'rgba(255, 0, 0)',
};

const ENV_NAME = {
	'DEV': 'DEVELOPMENT',
	'UAT': 'STAGING',
	'PROD': 'PRODUCTION',
};

const DIV_STYLE = {
	fontFamily: 'RobotoCondensed',
	position: 'absolute',
	bottom: '50%',
	color: '#000',
	fontSize: '2em',
	writingMode: 'vertical-rl',
	transform: 'rotate(-180deg)',
	letterSpacing: '10px',
	lineHeight: '1'
};

const isLocalhost = window.location.hostname === 'localhost';

export default function EnvironmentStrip() {

	if (currentEnviroment === 'PROD' && !isLocalhost) {
		return <></>;
	}

	return (
		<div style={{ backgroundColor: `${ENV_COLOR[currentEnviroment]}`, zIndex: 999, height: '100%', width: '30px', position: 'absolute', top: '0', left: '0' }}>
			<div style={DIV_STYLE}>
				{ENV_NAME[currentEnviroment]}
			</div>
		</div>
	);
}