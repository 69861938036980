import { SignalingClient, Role } from 'amazon-kinesis-video-streams-webrtc';
import { httpsCallable } from 'firebase/functions';
import { firebaseFunctions } from '../utils/firebase';

const viewer = {};

export async function startViewer(remoteView, deviceId, onRemoteStreamReceived, onRemoteDataMessage, onWebRtcError) {

	viewer.remoteView = remoteView;

	// get the function to request data
	const getOrCreateKinesisChannel = httpsCallable(firebaseFunctions, 'getOrCreateKinesisChannel');

	// request data to be able to access WebRTC
	const resultKinesisChannel = await getOrCreateKinesisChannel({ deviceId, role: 'VIEWER' });

	// https://github.com/awslabs/amazon-kinesis-video-streams-webrtc-sdk-js/issues/88
	const myCustomRequestSigner = {
		getSignedURL: () => { return resultKinesisChannel.data.signedURL; }
	};

	// Create Signaling Client
	viewer.signalingClient = new SignalingClient({
		channelARN: resultKinesisChannel.data.channelARN,
		channelEndpoint: resultKinesisChannel.data.endpointsByProtocol.WSS,
		clientId: resultKinesisChannel.data.clientId,
		role: Role.VIEWER,
		region: resultKinesisChannel.data.region,
		requestSigner: myCustomRequestSigner
	});

	const constraints = {
		// video: { width: { ideal: 720 }, height: { ideal: 1280 } },
		audio: true,
	};

	const configuration = {
		iceServers: resultKinesisChannel.data.iceServers,
		iceTransportPolicy: "all",
	};

	viewer.peerConnection = new RTCPeerConnection(configuration);

	viewer.dataChannel = viewer.peerConnection.createDataChannel(
		"kvsDataChannel"
	);

	viewer.peerConnection.ondatachannel = (event) => {
		event.channel.onmessage = onRemoteDataMessage;
	};

	// Poll for connection stats
	/*viewer.peerConnectionStatsInterval = setInterval(
	  () => viewer.peerConnection.getStats().then(onStatsReport),
	  1000
	);*/

	viewer.signalingClient.on("open", async () => {
		console.log("[VIEWER] Connected to signaling service");

		// Get a stream from the webcam, add it to the peer connection, and display it in the local view
		try {

			viewer.localStream = await navigator.mediaDevices.getUserMedia(constraints);

			viewer.localStream.getTracks().forEach((track) => viewer.peerConnection.addTrack(track, viewer.localStream));

			//Mute Local Microphone
			viewer.localStream.getAudioTracks()[0].enabled = false;
			//localView.srcObject = viewer.localStream;

		} catch (e) {
			console.error("[VIEWER] Could not find webcam");
		}

		// Create an SDP offer to send to the master
		console.log("[VIEWER] Creating SDP offer");
		await viewer.peerConnection.setLocalDescription(
			await viewer.peerConnection.createOffer({
				offerToReceiveAudio: true,
				offerToReceiveVideo: true,
			})
		);

		// When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
		console.log("[VIEWER] Sending SDP offer");
		viewer.signalingClient.sendSdpOffer(viewer.peerConnection.localDescription);
		console.log("[VIEWER] Generating ICE candidates");

	});

	viewer.signalingClient.on("sdpAnswer", async (answer) => {
		// Add the SDP answer to the peer connection
		console.log("[VIEWER] Received SDP answer");
		try {
			await viewer.peerConnection.setRemoteDescription(answer);
		} catch (error) {
			onWebRtcError(error);
		}
	});

	viewer.signalingClient.on("iceCandidate", (candidate) => {
		// Add the ICE candidate received from the MASTER to the peer connection
		console.log("[VIEWER] Received ICE candidate");

		try {
			viewer.peerConnection.addIceCandidate(candidate);
		} catch (error) {
			onWebRtcError(error);
		}

	});

	viewer.signalingClient.on("close", () => {
		console.log("[VIEWER] Disconnected from signaling channel");
	});

	viewer.signalingClient.on("error", (error) => {
		console.error("[VIEWER] Signaling client error: ", error);
	});

	// Send any ICE candidates to the other peer
	viewer.peerConnection.addEventListener("icecandidate", ({ candidate }) => {
		if (candidate) {
			console.log("[VIEWER] Generated ICE candidate");

			// When trickle ICE is enabled, send the ICE candidates as they are generated.

			console.log("[VIEWER] Sending ICE candidate");

			try {
				viewer.signalingClient.sendIceCandidate(candidate);
			} catch (error) {
				onWebRtcError(error);
			}

		} else {
			console.log("[VIEWER] All ICE candidates have been generated");

			// When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
			/*if (!formValues.useTrickleICE) {
			  console.log("[VIEWER] Sending SDP offer");
			  viewer.signalingClient.sendSdpOffer(
				viewer.peerConnection.localDescription
			  );
			}*/
		}
	});

	// As remote tracks are received, add them to the remote view
	viewer.peerConnection.addEventListener("track", (event) => {
		console.log("[VIEWER] Received remote track");
		if (remoteView && remoteView.srcObject) {
			return;
		}
		onRemoteStreamReceived();
		viewer.remoteStream = event.streams[0];
		if (remoteView) {
			remoteView.srcObject = viewer.remoteStream;
		}
	});

	console.log("[VIEWER] Starting viewer connection");
	viewer.signalingClient.open();
}

export function getSignalingClient() {
	return viewer.signalingClient;
}

export function stopViewer() {
	console.log("[VIEWER] Stopping viewer connection");
	if (viewer.signalingClient) {
		viewer.signalingClient.close();
		viewer.signalingClient = null;
	}

	if (viewer.peerConnection) {
		viewer.peerConnection.close();
		viewer.peerConnection = null;
	}

	if (viewer.localStream) {
		viewer.localStream.getTracks().forEach((track) => track.stop());
		viewer.localStream = null;
	}

	if (viewer.remoteStream) {
		viewer.remoteStream.getTracks().forEach((track) => track.stop());
		viewer.remoteStream = null;
	}

	if (viewer.peerConnectionStatsInterval) {
		clearInterval(viewer.peerConnectionStatsInterval);
		viewer.peerConnectionStatsInterval = null;
	}

	/*if (viewer.localView) {
	  viewer.localView.srcObject = null;
	}*/

	if (viewer.remoteView) {
		viewer.remoteView.srcObject = null;
	}

	if (viewer.dataChannel) {
		viewer.dataChannel = null;
	}
}

export function getRemoteStream() {
	return (viewer && viewer.remoteStream ? viewer.remoteStream : undefined)
}

export function getViewer() {
	return viewer
}


export function sendViewerMessage(message, callback, onWebRtcError) {
	if (viewer.dataChannel && viewer.dataChannel.readyState === "open") {
		try {
			viewer.dataChannel.send(message);
			if (callback) callback();
		} catch (error) {
			onWebRtcError(error);
		}
	}
}